import React from 'react'
import Img1 from '../assets/hlabadike-logo.png'
import Img2 from '../assets/crispshine.jpeg'

function Brands() {
  return (
  
        <section class="bg-white py-20 lg:py-[120px]" id="brands">
        <div class="container mx-auto">
        <div className='flex items-center justify-center'>
            <h2 className='h2 leading-tight text-accent'> Clients</h2>
        </div>
            <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
                <div class="flex flex-wrap items-center justify-center">
                <a
                    href="javascript:void(0)"
                    class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
                >
                    <img
                    src={Img1}
                    alt="image"
                    class="h-10 w-full"
                    />
                </a>
                <a
                    href="javascript:void(0)"
                    class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
                >
                    <img
                    src="https://cdn.tailgrids.com/2.0/image/assets/images/brands/lineicons.svg"
                    alt="image"
                    class="h-10 w-full"
                    />
                </a>
                <a
                    href="javascript:void(0)"
                    class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
                >
                    <img
                    src="https://cdn.tailgrids.com/2.0/image/assets/images/brands/uideck.svg"
                    alt="image"
                    class="h-10 w-full"
                    />
                </a>
                <a
                    href="javascript:void(0)"
                    class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]"
                >
                    <img
                    src="https://cdn.tailgrids.com/2.0/image/assets/images/brands/ayroui.svg"
                    alt="image"
                    class="h-10 w-full"
                    />
                </a>
                </div>
            </div>
            </div>
        </div>
        </section>
  );
};

export default Brands;
