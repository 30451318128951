import React from 'react';
import Image from '../assets/avatar.png';
import {FaGithub, FaLinkedin,FaTwitter} from 'react-icons/fa';
import { TypeAnimation } from 'react-type-animation';
import {motion} from "framer-motion";
import {fadeIn} from '../variants';
import { Link } from 'react-scroll';


const Banner = () => {
  return(
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1 variants={fadeIn('up',0.3)} initial ='hidden' whileInView={'show'} viewport={{once:false,amount: 0.7}} className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
              MOLATO <span>SEKGOBELA</span>
            </motion.h1>
            <motion.div variants={fadeIn('up',0.3)} initial ='hidden' whileInView={'show'} viewport={{once:false,amount: 0.7}} className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='text-white mr-4'>I am a</span>
              <TypeAnimation sequence={['Software Engineer',2000,'Application Developer',2000,'Tech Enthusiast',2000,]} speed={50} className='text-accent' wrapper ='span' repeat={Infinity} />
            </motion.div>
            <motion.p variants={fadeIn('up',0.5)} initial ='hidden' whileInView={'show'} viewport={{once:false,amount: 0.7}} className='mb-8 max-w-lg mx-auto lg:mx-0'>
            I have 6+ years experience as a Software Engineer, over the years I have become highly skilled 
            in Web Development and Custom application Designs. My Tech stack includes Python(Django,Flask), ReactJS, AWS, Snowflake
            </motion.p>

          <motion.div variants={fadeIn('up',0.6)} initial ='hidden' whileInView={'show'} viewport={{once:false,amount: 0.7}} className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
          <Link smooth={true} spy={true}  to='contact'>
            <button className='btn btn-lg'>Contact me</button>
            </Link>
            <Link smooth={true} spy={true}  to='work'>
            <a href="#" className='text-gradient btn-link'>
            
              My Portfolio
            </a>
           </Link>
          </motion.div>
          <motion.div variants={fadeIn('up',0.7)} initial ='hidden' whileInView={'show'} viewport={{once:false,amount: 0.7}} className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'> 
            <a href='#' className='hover:bg-violet-600'>
              <FaLinkedin />
            </a>
            <a href='#' className='hover:bg-violet-600'>
              <FaGithub />
            </a>
            <a href='#' className='hover:bg-violet-600'>
              <FaTwitter />
            </a>
          </motion.div>
          </div>
          <motion.div variants={fadeIn('down',0.5)} initial ='hidden' whileInView={'show'}  className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px'>
            <img src={Image} alt='' />
          </motion.div>
        </div>

      </div>
    
    </section>

  ) 
};

export default Banner;
