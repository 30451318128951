import React from 'react';
import {BsArrowUpRight} from 'react-icons/bs'
import { motion } from "framer-motion";
import { fadeIn } from '../variants';
import { Link } from 'react-scroll';


const services =[{
  name: 'Software Development',
  description:
  'Our custom web development solutions accelerate workflows, boost revenues and optimize business operations from concept-to-code and development-to-deployment.',
  link: 'Learn more'
},
{
  name: 'UX/UI Design',
  description:
  'Leverage the latest UI/UX technologies, architectures, and trends to design responsive & scalable apps that transform customer experiences across multiple channels.',
  link: 'Learn more'
},
{
  name: 'Custom Application Development',
  description:
  'I rely on our industry-specific technology experience to deliver highly scalable, flexible, and interoperable web, mobile, desktop, and hybrid applications.',
  link: 'Learn more'
},
{
  name: 'API Development',
  description:
  'I develop reliable, well-documented, and easy-to-consume APIs that enable flexible integrations and customization of existing software products.',
  link: 'Learn more'
},


];
const Services = () => {
  return (
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text */}
          <motion.div 
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{once: false, amount: 0.3}}
             className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mx-blend-lighten mb-12 lg:mb-0'>
              <h2 className='h2 text-accent mb-6'>What I Do.</h2>
              <h3 className='h3 max-w-[455px] mb-16'>
              I'm a Software Engineer with over 5 years og experience.
              </h3>
              <Link smooth={true} spy={true} offset={-200} to='contact'>
                <button className='btn btn-sm'>See my Work</button>
              </Link>
          </motion.div>
          {/* Services */}
          <motion.div 
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{once: false, amount: 0.3}}>
            {/*services list*/}
            <div>
               {services.map((service, index)=>{
                const {name, description, link} = service;
                return(
                  <div className='border-b border-white/20 h-[146px] mb-[38px] flex' key={index}>
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
                        {name}
                        </h4>
                      <p className='font-secondary leading-tight'>
                        {description}
                        </p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <a href='#' className='btn w-9 h-9 mb-[42px] flex justify-center items-center'>
                        <BsArrowUpRight />
                      </a>
                      <a href='#' className='text-gradient text-sm'>{link}</a>
                    </div>
                  </div>
                );
               })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>

  );
};

export default Services;
