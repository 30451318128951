import React from 'react';
import CountUp from 'react-countup';
import {useInView, userInView} from 'react-intersection-observer'
import {motion} from "framer-motion"
import {fadeIn} from '../variants'
import { Link } from 'react-scroll';

const About = () => {

  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
  
  <section className='section' id='about' ref={ref}>
    <div className='container mx-auto'>
      <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
        {/* img  */}
      <motion.div 
      variants={fadeIn('right', 0.3)}
      initial='hidden'
      whileInView={'show'}
      viewport={{once: false, amount: 0.3}}
      className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'></motion.div>
      {/* text */}
      <motion.div 
       variants={fadeIn('left', 0.5)}
       initial='hidden'
       whileInView={'show'}
       viewport={{once: false, amount: 0.3}}
      className='flex-1'>
        <h2 className='h2 text-accent'>About me.</h2>
        <h3 className='h3 mb-4'>I'm a Software Engineer.</h3>
        <p>
        I'm an experienced software engineer who constantly seeks out innovative solutions to everyday problems. In my six years in this industry,
         I've honed my analytical thinking and collaboration skills, and I love working with a team.
        Strong in design and integration with intuitive problem-solving skills. Proficient in PYTHON (DJANGO) and REACTJS
        . Passionate about implementing and launching new projects. Ability to translate business requirements into technical solutions.
        </p>
        
        {/*stats */}
        <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
          <div>
            <div className='text-[40px] font-tertiary text-gradient mb-2'>
              { inView ?
               <CountUp start={0} end={6} duration={3} /> : null}
            </div>
            <div className='font-primary text-sm tracking-[2px]'>
              Years of <br />Experience
            </div>
          </div>
          <div>
            <div className='text-[40px] font-tertiary text-gradient mb-2'>
              { inView ?
               <CountUp start={0} end={31} duration={3} /> : null}
               
            </div>
            <div className='font-primary text-sm tracking-[2px]'>
              Projects <br />Completed
            </div>
          </div>
          <div>
            <div className='text-[40px] font-tertiary text-gradient mb-2'>
              { inView ?
               <CountUp start={0} end={9} duration={3} /> : null}
            </div>
            <div className='font-primary text-sm tracking-[2px]'>
              Satisfied <br />Clients
            </div>
          </div>
          
        </div>
        <div  className='flex gap-x-8 items-center'>
            <Link smooth={true} spy={true}  to='contact'>
              <button className='btn btn-lg'>Contact me</button>
              </Link>
              <Link smooth={true} spy={true}  to='work'>
              <a href ="#" className='text-gradient btn-link'>My Portfolio</a>
              </Link>
            </div>
          </motion.div>
      </div>
      </div>
    </section>
  );
};

export default About;
